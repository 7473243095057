export class MrPagination extends HTMLElement {
	static get observedAttributes() {
		return [
			'disabled',
			'for',
		];
	}

	constructor() {
		super();

		this.changeHandler = () => {
			this.render();
		};
	}

	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'disabled' === attrName ) {
			this._removeEventListeners();

			if ( null === newVal ) {
				this._addEventListeners();
			}

			return;
		}

		if ( 'for' === attrName ) {
			this._removeEventListeners();
			this._addEventListeners();

			return;
		}
	}

	_addEventListeners() {
		const forEl = this.forEl;

		if ( forEl ) {
			forEl.addEventListener( 'change', this.changeHandler );
		}
	}

	_removeEventListeners() {
		const forEl = this.forEl;

		if ( forEl ) {
			forEl.removeEventListener( 'change', this.changeHandler );
		}
	}

	render() {
		const forEl = this.forEl;
		if ( !forEl ) {
			return;
		}

		let max = forEl.max + 1;
		if ( Number.isNaN( max ) ) {
			return;
		}

		if ( 10 > max ) {
			max = '0' + max;
		}

		let current = forEl.value + 1;
		if ( Number.isNaN( current ) ) {
			return;
		}

		if ( 10 > current ) {
			current = '0' + current;
		}

		this.innerHTML = `${current} / ${max}`;
	}
}

customElements.define( 'mr-pagination', MrPagination );
