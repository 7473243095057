if ( 'IntersectionObserver' in window ) {
	const headerObserver: IntersectionObserver = new IntersectionObserver( ( entries ) => {
		for ( const entry of entries ) {
			// Interpret as "visible" from "0.05", this gives a more natural transition.
			if ( entry.isIntersecting && 0.05 < entry.intersectionRatio ) {
				document.body.classList.remove( 'is-scrolled' );
			} else {
				document.body.classList.add( 'is-scrolled' );
			}
		}
	}, {
		threshold: [
			0,
			0.05,
			0.1,
		],
	} );

	const initHeaderObserver = () => {
		const observed = document.querySelector( '[alter-site-header-while-this-is-visible]' );
		if ( !observed ) {
			return;
		}

		headerObserver.observe( observed );
	};

	initHeaderObserver();
	requestAnimationFrame( () => {
		initHeaderObserver();
	} );

	window.addEventListener( 'load', () => {
		initHeaderObserver();
	} );
}
