export const translate = ( data, key ) => {
	const lang = siteLang();

	if ( 'name' === key ) {
		if ( 'nl' === lang ) {
			return data.name || '';
		} else if ( 'en' === lang ) {
			return data.name_en || data.name || '';
		}
	}

	return '';
};

let lang = '';
export const siteLang = () => {
	if ( !lang ) {
		lang = document.documentElement.getAttribute( 'lang' );
	}

	// Only 'en' and 'nl' allowed for now.
	if ( 'en' === lang ) {
		return 'en';
	}

	return 'nl';
};

export const siteLocale = () => {
	// Only 'en' and 'nl' allowed for now.
	if ( 'en' === siteLang() ) {
		return 'en-US';
	}

	return 'nl-BE';
};
