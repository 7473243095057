class MrHighlightsCarousel extends HTMLElement {
	// MARK: properties.

	#currentIndex = 0;

	#duration = 6000;

	connectedCallback() {
		requestAnimationFrame( () => {
			setTimeout( () => {
				this.goToNextItem();
			}, this.#duration );
		} );
	}

	disconnectedCallback() {
		this.#currentIndex = 0;
	}

	private goToNextItem() {
		let newIndex = this.#currentIndex + 1;

		const items = this.querySelectorAll( '[data-carousel-item]' );

		// There is only one item, so stop here.
		if ( items.length < 2 ) {
			return;
		}

		if ( newIndex >= items.length ) {
			newIndex = 0;
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );

		setTimeout( () => {
			this.goToNextItem();
		}, this.#duration );
	}

	private updateState( index: number ) {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const length = items.length;

		// Get current and next items.
		const current = items[index];
		const next = items[indexPlusOne( index, length, true )];
		const prev = items[indexMinusOne( index, length, true )];

		// Set attributes.
		// First reset all items.
		items.forEach( ( item ) => {
			item.removeAttribute( 'data-carousel-item-current' );
			item.removeAttribute( 'data-carousel-item-next' );
			item.removeAttribute( 'data-carousel-item-previous' );

			const label = item.querySelector( 'a[href]' );
			if ( !label || document.activeElement === label ) {
				return;
			}

			label.setAttribute( 'tabindex', '-1' );
		} );

		current.setAttribute( 'data-carousel-item-current', '' );
		next.setAttribute( 'data-carousel-item-next', '' );
		prev.setAttribute( 'data-carousel-item-previous', '' );

		const label = current.querySelector( 'a[href]' );
		if ( !label || document.activeElement === label ) {
			return;
		}

		label.removeAttribute( 'tabindex' );
	}
}

customElements.define( 'mr-highlights-carousel', MrHighlightsCarousel );

function indexMinusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index--;

	if ( 0 > index ) {
		if ( looping ) {
			return maxValue - 1;
		}

		return 0;

	}

	return index;
}

function indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
